// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogpost-js": () => import("/opt/build/repo/src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-index-js": () => import("/opt/build/repo/src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-blogpost-js": () => import("/opt/build/repo/src/pages/blogpost.js" /* webpackChunkName: "component---src-pages-blogpost-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-index-js": () => import("/opt/build/repo/src/pages/music/index.js" /* webpackChunkName: "component---src-pages-music-index-js" */),
  "component---src-pages-resume-index-jsx": () => import("/opt/build/repo/src/pages/resume/index.jsx" /* webpackChunkName: "component---src-pages-resume-index-jsx" */),
  "component---src-pages-resume-resume-jsx": () => import("/opt/build/repo/src/pages/resume/Resume.jsx" /* webpackChunkName: "component---src-pages-resume-resume-jsx" */),
  "component---src-pages-resume-timeline-jsx": () => import("/opt/build/repo/src/pages/resume/Timeline.jsx" /* webpackChunkName: "component---src-pages-resume-timeline-jsx" */)
}

