import React from 'react';
import PropTypes from 'prop-types';

const defaultState = {
};

const AppContext = React.createContext(defaultState);

class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    this.audioPlayers = [];
  }

  addPlayer = ({ id, playerRef }) => {
    if (!this.audioPlayers[id]) {
      this.audioPlayers[id] = playerRef;
    }
    // this.setState({ audioPlayers})
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider>
        {children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContext;

export { AppProvider };
